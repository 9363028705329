<template>
    <div class="shop_main_store" v-loading="isRequestLoading">
        <StoreHeader :type="1" :backgroundImage="backgroundImage" :categoryList="categoryList" :storeName="storeName" :isRequestLoading="isRequestLoading" />

        <div class="shopStoreBanner" v-if="storeBanner.length > 0">
            <el-carousel trigger="click" height="650px">
                <el-carousel-item v-for="(item, index) in storeBanner" :key="index">
                    <el-image :src="imageURL + item.banner" class="image" fit="cover"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>

        <StoreGoods :type="1" :storeName="storeName" :corporatePhone="corporatePhone" :legalBusinessScope="legalBusinessScope" :showImageList="showImageList" :isCollect="isCollect" :floor="floor" @toCollect="postStoreCollect" />
    </div>
</template>

<script>
  import {imageURL} from "../../../utils/config";
  import {getShopStore, getShopStoreCollect, postShopStoreCollect} from "../../../api/shop/store";
  import {getStoreInfo} from "../../../api/shop/goods";
  import {mapState} from "vuex";

  const StoreHeader = () => import('./header');
  const StoreGoods = () => import('./goods');

  export default {
    name: "shopStore",
    data () {
      return {
        imageURL: imageURL,
        isRequestLoading: false,
        keyword: '',
        storeBanner: [],
        backgroundImage: '',
        categoryList: [],
        storeName: '',
        corporatePhone: '',
        legalBusinessScope: '',
        showImageList: [],
        isCollect: false,
        floor: {}
      }
    },
    computed: {
      ...mapState({
        isLogin: state => state.user.isLogin,
      })
    },
    created() {
      this.getInfo();
      this.getStoreInfo();
      if (this.isLogin) {
        this.getStoreCollect();
      }
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true;
        getShopStore(this.$route.query.id).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.storeBanner = res.data.storeBanner ? res.data.storeBanner.filter(item => item.auditStatus === 2) : [];
            this.categoryList = res.data.categoryList ? res.data.categoryList : [];
            this.backgroundImage = res.data.backgroundImage;
            this.storeName = res.data.storeName;
            this.floor = res.data.floorList ? res.data.floorList : [];
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getStoreInfo () {
        getStoreInfo(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.corporatePhone = res.data.corporatePhone;
            this.legalBusinessScope = res.data.legalBusinessScope;
            this.showImageList = [imageURL + res.data.businessLicenseImage]
          }
        })
      },
      getStoreCollect () {
        getShopStoreCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.isCollect = res.data;
          }
        })
      },
      postStoreCollect () {
        postShopStoreCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.getStoreCollect();
          }
        })
      },
      toSearch () {}
    },
    components: {
      StoreHeader, StoreGoods
    }
  }
</script>

<style lang="scss" scoped>
    @import "../../../assets/style/store";
</style>
